<template>
    <section class="footer" v-if="visible">
        <div
            v-if="visible"
            class="columns is-mobile p-0 m-0 is-fullwidth is-vcentered"
        >
            <div class="column m-0 p-0">
                <div class="is-flex">
                    <!-- <ColorModePicker /> -->

                    <div class="px-0">
                        <b-button
                            type="social-btn is-link is-rounded has-text-subtle"
                            target="_blank"
                            tag="a"
                            :href="iglink"
                        >
                            <b-image
                                :src="
                                    $colorMode.value == 'dark'
                                        ? require('~/assets/images/ig-icon.svg')
                                        : require('~/assets/images/ig-icon-dark.svg')
                                "
                                alt="TDF*P su Instagram"
                                class="social-icon"
                            />
                        </b-button>

                        <b-button
                            type="social-btn is-link is-rounded has-text-subtle"
                            target="_blank"
                            tag="a"
                            :href="fblink"
                        >
                            <b-image
                                :src="
                                    $colorMode.value == 'dark'
                                        ? require('~/assets/images/facebook-icon.svg')
                                        : require('~/assets/images/facebook-icon-dark.svg')
                                "
                                alt="TDF*P su Facebook"
                                class="social-icon"
                            />
                        </b-button>
                    </div>
                </div>
                <div class="is-flex mt-2">
                    <div
                        class="w-third start is-small is-subtle is-size-7 has-text-weight-bold mx-1"
                    >
                        {{ ccDate.getFullYear() }} &copy; TDF*P
                    </div>
                    <div class="w-third">
                        <LocalePicker />
                    </div>
                    <div class="w-third end">
                        <b-dropdown
                            append-to-body
                            trap-focus
                            aria-role="menu"
                            position="is-top-left"
                            :mobile-modal="false"
                            class="i18n-dropdown"
                        >
                            <template #trigger>
                                <a
                                    class="is-size-7 has-text-weight-bold is-subtle is-uppercase is-rounded"
                                >
                                    {{ $t("footer.legal") }}
                                </a>
                            </template>

                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <a
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    onclick="window.displayPreferenceModal();return false;"
                                >
                                    {{ $t("preferences.title") }}
                                </a>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <nuxt-link
                                    @click.native="onClickOut"
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    :to="localePath('/cookies')"
                                >
                                    {{ $t("cookies.title") }}
                                </nuxt-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <nuxt-link
                                    @click.native="onClickOut"
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    :to="localePath('/privacy')"
                                >
                                    {{ $t("privacy.title") }}
                                </nuxt-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <nuxt-link
                                    @click.native="onClickOut"
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    :to="localePath('/terms')"
                                >
                                    {{ $t("terms.title") }}
                                </nuxt-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <nuxt-link
                                    @click.native="onClickOut"
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    :to="localePath('/refunds')"
                                >
                                    {{ $t("refunds.title") }}
                                </nuxt-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <a
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    href="https://www.tempiodelfuturo.art/uploads/statuto_movement-aps.pdf"
                                    target="_blank"
                                >
                                    {{ $t("statute.movement") }}
                                    <b-icon
                                        icon="external-link-alt"
                                        size="is-small"
                                    />
                                </a>
                            </b-dropdown-item>
                            <b-dropdown-item
                                class="dropdown-item is-uppercase is-rounded"
                                has-link
                                aria-role="menuitem"
                            >
                                <a
                                    class="is-small is-uppercase is-size-7 has-text-weight-bold mx-0 px-0"
                                    href="https://www.tempiodelfuturo.art/uploads/statuto_nuovo-rinascimento.pdf"
                                    target="_blank"
                                >
                                    {{ $t("statute.rinascimento") }}
                                    <b-icon
                                        icon="external-link-alt"
                                        size="is-small"
                                    />
                                </a>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <!-- :class="activePage == 'privacy' ? 'has-text-link' : ''" -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ColorModePicker from "~/components/ColorModePicker";
import LocalePicker from "~/components/LocalePicker";
// import InstagramIcon from "@/assets/images/ig-icon.svg?inline";

export default {
    name: "AppFooter",
    // transition: "fade",
    components: {
        ColorModePicker,
        LocalePicker,
        // InstagramIcon,
        // SfImage,
        // SfMenuItem,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        onClickOut: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            // activePage: "",
            iglink: "https://www.instagram.com/tempiodelfuturoperduto/",
            fblink: "https://facebook.com/tempiodelfuturoperduto",
            isMobile: false,
            ccDate: new Date(),
        };
    },

    mounted() {
        // this.activePage = this.$router.currentRoute.name;
    },
};
</script>

<style lang="scss">
.w-third {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    &.start {
        justify-content: flex-start;
    }

    &.end {
        justify-content: flex-end;
    }
    // @media (max-width: 960px) {
    //     width: 50%;
    // }
}
// .footer {
//     height: auto;
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     right: 0;
// }
.has-family-logo {
    // font-family: "Old English Text MT", serif;
    font-weight: 100;
}
.social-icon {
    width: 1.1em;
    // height: 1.1em;
}

.social-btn {
    // width: 1em;
    margin: 0 !important;
    padding: 0.3em !important;
    height: fit-content;
    line-height: 1.1em;
}

.is-flex {
    justify-content: space-between;
    align-items: center;
}
.logomark {
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    // margin: 1em 0;
}
.footer-logotype {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1em;
    // margin: 1em 0;
}
.inset-4em {
    padding-left: 4em;
}
.is-inset-mobile {
    @media (max-width: 960px) {
        padding-left: 3.5em;
    }
}
.is-fullwidth {
    width: 100%;
    display: flex;
    @media (min-width: 960px) {
        max-width: 1240px;

        margin: 0 2em;
    }
}
.is-inline {
    > * {
        display: inline;
        margin: 0 1em;
    }
}

.footer {
    // position: fixed;
    bottom: 0;
    left: 0;
    z-index: 0 !important;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    height: auto;
    margin: 0 auto;
    // align-content: space-around;
    width: 100%;
    background: none;
    // max-height: 200px;
    // border-top: 2px solid var(--bg-secondary);
    // padding-bottom: 2em;
    @media (min-width: 960px) {
        max-width: 1240px;
        padding: 1rem;
        // margin: 0 5em;
    }
    @media (max-width: 960px) {
        // max-width: 1240px;
        padding: 1.5rem 1.5rem;
        // margin: 0 5em;
    }
}
</style>