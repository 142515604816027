// holds your root state
export const state = () => ({
    hasSubmittedForm: false,
    isLoggedIn: false,
    isInternalLoggedIn: false,
    isMobile: false,
    currpagetitle: "TDF*P",
    // auth: {
    //     access_token: null,
    //     refresh_token: null,
    //     expires_in: null,
    //     token_type: null,
    // },
    // "public-auth": {
    //     pub_acc_token: null,
    //     pub_ref_token: null,
    //     pub_expires_in: null,
    //     pub_token_type: null,
    // },
    // user: {
    //     id: null,
    //     name: null,
    //     email: null,
    //     role: null,
    //     created_at: null,
    //     updated_at: null,
    // },
});

// contains your actions
export const actions = {
    confirmSubmission({ state, commit }) {
        commit("setSubmission", true);
    },
    clearSubmission({ state, commit }) {
        commit("setSubmission", false);
    },

    setLoggedIn({ state, commit }, value) {
        commit("setLoggedIn", value);
    },
    setInternalLoggedIn({ state, commit }, value) {
        commit("setInternalLoggedIn", value);
    },

    setCurrPageTitle({ state, commit }, value) {
        commit("setCurrPageTitle", value);
    },
    async nuxtServerInit({ dispatch, commit, state }) {
        const { access_token, refresh_token } = state.auth;
        const { pub_acc_token, pub_ref_token } = state["public-auth"];
        // console.log("nuxtServerInit", state);

        // console.log("nuxtServerInit", access_token, refresh_token);
        if (access_token && refresh_token) {
            try {
                // refresh the access token
                await dispatch("auth/refresh");
            } catch (e) {
                // catch any errors and automatically logout the user
                await dispatch("auth/logout");
            }
        }

        if (pub_acc_token && pub_ref_token) {
            try {
                // refresh the access token
                await dispatch("public-auth/refresh");
            } catch (e) {
                // console.log("nuxtServerInit", e);
                // catch any errors and automatically logout the user
                await dispatch("public-auth/logout");
            }
        }
    },
};
// contains your mutations
export const mutations = {
    setSubmission(state, value) {
        state.hasSubmittedForm = value;
    },
    setLoggedIn(state, value) {
        state.isLoggedIn = value;
    },
    setInternalLoggedIn(state, value) {
        state.isInternalLoggedIn = value;
    },

    setCurrPageTitle(state, value) {
        state.currpagetitle = value;
    },
};
// your root getters
export const getters = {
    getFormStatus(state) {
        return state.hasSubmittedForm;
    },
    getLoggedInStatus(state) {
        return state.isLoggedIn;
    },
    getInternalLoggedInStatus(state) {
        return state.isInternalLoggedIn;
    },
    getIsMobile() {
        if (process.client) {
            if (
                // window.innerWidth < 768 ||
                window.navigator.userAgent.match(
                    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
                ) ||
                window.navigator.userAgent.match(/Mobile/i) ||
                window.navigator.userAgent.match(/Tablet/i)
                // ||
                // // ipad pro but not macos
                // window.navigator.userAgent.match(/Macintosh/i)
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    },
    getCurrPageTitle(state) {
        return state.currpagetitle;
    },
};
