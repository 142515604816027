import Vue from "vue";

import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(
    AOS.init({
        // Global settings:
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        once: false, // whether animation should happen only once - while scrolling down
        mirror: true, // whether elements should animate out while scrolling past them
        // anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation

        // // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        // offset: 120, // offset (in px) from the original trigger point
        // delay: 0, // values from 0 to 3000, with step 50ms
        // duration: 400, // values from 0 to 3000, with step 50ms
        // easing: "ease", // default easing for AOS animations
        // once: false, // whether animation should happen only once - while scrolling down
        // mirror: true, // whether elements should animate out while scrolling past them
        // anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    })
);
