<template>
    <div class="toggle-flex mt-1">
        <ColorScheme placeholder="..." tag="div" class="toggle-info">
            <b-icon
                icon-pack="fas"
                class="has-text-subtle mr-1"
                :icon="
                    $colorMode.value === 'light' ? 'fas fa-sun' : 'fas fa-moon'
                "
            />
            <div class="vertical-flex">
                <b-field>
                    <b-switch
                        v-model="color"
                        @input="$colorMode.preference = color"
                        value="dark"
                        true-value="dark"
                        false-value="dark"
                        type="is-dark"
                        passive-type="is-light"
                        class="has-text-subtle mr-0 is-small"
                    >
                    </b-switch>
                </b-field>
            </div>
            <div class="is-subtle theme-label ml-1 is-size-7">
                {{
                    $colorMode.value == "dark"
                        ? this.$t("components.colorPicker.darkLabel")
                        : this.$t("components.colorPicker.lightLabel")
                }}
            </div>
        </ColorScheme>
    </div>
</template>

<script>
// import IconSystem from "@/assets/icons/system.svg?inline";
// import IconLight from "@/assets/icons/light.svg?inline";
// import IconDark from "@/assets/icons/dark.svg?inline";
// import IconSepia from "@/assets/icons/sepia.svg?inline";

export default {
    name: "ColorModePicker",
    components: {
        // IconSystem,
        // IconLight,
        // IconDark,
        // IconSepia,
    },
    data() {
        return {
            color: this.$colorMode.preference,
            colors: ["dark"],
        };
    },
    // methods: {
    //   getClasses(color) {
    //     // Does not set classes on ssr preference is system (because we know them on client-side)
    //     if (this.$colorMode.unknown) {
    //       return {};
    //     }
    //     return {
    //       preferred: color === this.$colorMode.preference,
    //       selected: color === this.$colorMode.value,
    //     };
    //   },
    // },
};
</script>

<style lang="scss" scoped>
.theme-label {
    opacity: 0;
    transition: 0.25s;
}
.vertical-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dark-mode-toggle {
    color: var(--color-subtle);
    width: auto;
    display: flex;
    align-items: center;
}
.toggle-flex {
    color: var(--color-subtle);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.toggle-flex:hover {
    .theme-label {
        opacity: 1;
        // transition: 0.25s;
    }
}
.toggle-info {
    color: var(--color-subtle);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: inline-block;
  padding: 5px;
}
p {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 20px;
}
.feather {
  position: relative;
  top: 0px;
  cursor: pointer;
  padding: 7px;
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  margin: 0;
  border-radius: 5px;
  transition: all 0.1s ease;
}
.feather:hover {
  top: -3px;
}
.feather.preferred {
  border-color: var(--color-primary);
  top: -3px;
}
.feather.selected {
  color: var(--color-primary);
} */
</style>