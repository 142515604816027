var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-container is-family-secondary glassy-bg"},[_c('b-navbar',{staticClass:"is-transparent is-vcentered container has-text-link",attrs:{"close-on-click":false,"fixed-top":"","active":_vm.isActive},on:{"update:active":function($event){_vm.isActive=$event}}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{staticClass:"brand-logo",attrs:{"tag":"router-link","to":_vm.localePath('/')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('Logo'),_vm._v(" "),(_vm.mobile === false)?_c('span',{staticClass:"desktop-logo is-7 has-text-weight-bold is-uppercase",class:_vm.currentPage == '' ? 'has-text-link' : 'is-subtle'},[_vm._v("Tempio Del Futuro *Perduto")]):_c('span',{staticClass:"mt-1 is-7 has-text-weight-bold is-uppercase is-mobile-header",class:_vm.currentPage == '' ? 'has-text-link' : 'is-subtle'},[_vm._v(_vm._s(_vm.isActive
                            ? "TDF*P"
                            : this.$store.getters["getCurrPageTitle"]))])],1)],1),_vm._v(" "),(_vm.mobile === true)?_c('template',{slot:"burger"},[_c('div',{staticClass:"burger-container"},[_c('b-button',{staticClass:"burger-btn is-subtle is-family-secondary",class:{ 'is-active': _vm.isActive },attrs:{"as":"a"},on:{"click":function($event){!_vm.isActive ? _vm.animateMenuIn() : _vm.animateMenuOut()}}},[_c('b-icon',{staticClass:"burger-icon is-subtle",attrs:{"icon-pack":"fas","icon":_vm.isActive ? 'fas fa-times' : 'fas fa-user'}})],1)],1)]):_vm._e(),_vm._v(" "),_c('template',{slot:"end"},[_c('div',{staticClass:"menu-body"},[_c('div',[(
                            _vm.isActive &&
                            this.$store.getters['public-auth/authedUser']
                                .firstName &&
                            _vm.mobile === true
                        )?_c('div',{staticClass:"card grid-item-xl"},[_c('div',{staticClass:"card-content row-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"is-size-3 has-text-primary-alt has-text-weight-bold mb-2"},[_vm._v("\n                                    "+_vm._s(this.$store.getters[
                                            "public-auth/authedUser"
                                        ].firstName)+"\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('b-navbar-item',{staticClass:"menu-item logged-menu-item",class:_vm.currentPage == 'u'
                                            ? 'is-active'
                                            : '',attrs:{"tag":"router-link","to":_vm.localePath('/u')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"fas fa-user","size":"is-small"}}),_vm._v("\n                                    "+_vm._s(_vm.$t("profile.header"))+"\n                                ")],1),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item logged-menu-item is-subtle",attrs:{"tag":"router-link","to":_vm.localePath('/u/manage')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"fas fa-cog","size":"is-small"}}),_vm._v("\n                                    "+_vm._s(_vm.$t("profile.manageAccount"))+"\n                                ")],1),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item logged-menu-item logout-item",class:_vm.currentPage == 'u'
                                            ? 'is-active'
                                            : '',attrs:{"tag":"router-link","icon-right":"fas fa-sign-out-alt","to":_vm.localePath('/u')},nativeOn:{"click":function($event){return _vm.authLogout()}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"fas fa-sign-out-alt","size":"is-small"}}),_vm._v("\n                                    "+_vm._s(_vm.$t("actions.logout"))+"\n                                ")],1)],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"menu-scroller"},[(
                                _vm.mobile === true &&
                                // !loggedIn &&
                                !this.$store.getters[
                                    'public-auth/authedUser'
                                ].firstName
                            )?_c('b-navbar-item',{staticClass:"menu-item",class:_vm.currentPage == 'u' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('/u')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                            "+_vm._s(_vm.$t("profile.header"))+"\n                        ")]):_vm._e(),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item",class:_vm.currentPage == 'events' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('events')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                            "+_vm._s(_vm.$t("events.header"))+"\n                        ")]),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item",class:_vm.currentPage == 'contribute'
                                    ? 'is-active'
                                    : '',attrs:{"tag":"router-link","to":_vm.localePath('contribute')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                            "+_vm._s(_vm.$t("contribute.header"))+"\n                        ")]),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item",attrs:{"tag":"a","href":"https://artsandculture.google.com/partner/tempio-del-futuro-perduto","target":"_blank"},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                            "+_vm._s(_vm.$t("exhibit.header"))+"\n                        ")]),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item",class:_vm.currentPage == 'where' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('where')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                            "+_vm._s(_vm.$t("where.header"))+"\n                        ")]),_vm._v(" "),(
                                _vm.mobile === false &&
                                !this.$store.getters[
                                    'public-auth/authedUser'
                                ].firstName
                            )?_c('b-navbar-item',{staticClass:"menu-item is-primary-menu-item",class:_vm.currentPage == 'where' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('/u')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('b-icon',{staticClass:"mr-1 mb-1 is-subtle",attrs:{"icon":"fas fa-user","size":"is-small"}}),_vm._v("\n\n                            "+_vm._s(_vm.$t("profile.header"))+"\n                        ")],1):_vm._e(),_vm._v(" "),(
                                _vm.mobile === false &&
                                this.$store.getters[
                                    'public-auth/authedUser'
                                ].firstName
                            )?_c('b-navbar-item',{staticClass:"menu-item is-primary-menu-item",class:_vm.currentPage == 'where' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('/u')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('b-icon',{staticClass:"mr-1 mb-1 is-primary",attrs:{"icon":"fas fa-user","size":"is-small"}}),_vm._v("\n                            "+_vm._s(this.$store.getters[
                                    "public-auth/authedUser"
                                ].firstName)+"\n                        ")],1):_vm._e()],1)]),_vm._v(" "),_c('AppFooter',{attrs:{"visible":_vm.mobile,"onClickOut":_vm.animateMenuOut}})],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }