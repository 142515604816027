var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-container is-family-secondary glassy-bg"},[_c('div',{staticClass:"menu-backdrop-blur glassy-bg"}),_vm._v(" "),_c('b-navbar',{staticClass:"is-transparent is-vcentered container has-text-link",attrs:{"close-on-click":false,"fixed-top":"","active":_vm.isActive},on:{"update:active":function($event){_vm.isActive=$event}}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{staticClass:"brand-logo",attrs:{"tag":"router-link","to":_vm.localePath('/internal')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[(_vm.isIosPwaBackButton && _vm.canPressBack)?_c('b-button',{staticClass:"back-btn is-rounded is-subtle is-family-secondary",class:{ 'is-active': _vm.isActive },attrs:{"as":"a"},on:{"click":function($event){return _vm.goBack()}}},[_c('b-icon',{staticClass:"burger-icon is-subtle",attrs:{"icon-pack":"fas","icon":"fas fa-arrow-left"}})],1):_vm._e(),_vm._v(" "),_c('Logo'),_vm._v(" "),(_vm.mobile === false)?_c('span',{staticClass:"desktop-logo is-7 has-text-weight-bold is-uppercase",class:_vm.currentPage == '' ? 'has-text-link' : 'is-subtle'},[_vm._v("Tempio Del Futuro *Perduto")]):_c('span',{staticClass:"mt-1 is-7 has-text-weight-bold is-uppercase",class:_vm.currentPage == '' ? 'has-text-link' : 'is-subtle'},[_vm._v(_vm._s(_vm.isActive
                            ? "TDF*P"
                            : this.$store.getters["getCurrPageTitle"]))])],1)],1),_vm._v(" "),(_vm.mobile === true)?_c('template',{slot:"burger"},[_c('div',{staticClass:"burger-container"},[(this.$store.getters['auth/authedUser'].firstName)?_c('b-button',{staticClass:"burger-btn is-rounded is-subtle is-family-secondary",class:{ 'is-active': _vm.isActive },attrs:{"as":"a"},on:{"click":function($event){!_vm.isActive ? _vm.animateMenuIn() : _vm.animateMenuOut()}}},[_c('b-icon',{staticClass:"burger-icon is-subtle",attrs:{"icon-pack":"fas","icon":_vm.isActive ? 'fas fa-times' : 'fas fa-user'}})],1):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('template',{slot:"end"},[(
                    _vm.isActive &&
                    this.$store.getters['auth/authedUser'].firstName &&
                    _vm.mobile === true
                )?_c('div',{staticClass:"card grid-item-xl"},[_c('div',{staticClass:"card-content row-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"is-size-3 has-text-primary-alt has-text-weight-bold mb-2"},[_vm._v("\n                            "+_vm._s(this.$store.getters["auth/authedUser"]
                                    .firstName)+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('b-navbar-item',{staticClass:"menu-item logged-menu-item",class:_vm.currentPage == 'internal' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"fas fa-tachometer-alt","size":"is-small"}}),_vm._v("\n                            "+_vm._s(_vm.$t("internalDashboard.header"))+"\n                        ")],1),_vm._v(" "),_c('b-navbar-item',{staticClass:"menu-item logged-menu-item logout-item",class:_vm.currentPage == 'internal' ? 'is-active' : '',attrs:{"tag":"router-link","icon-right":"fas fa-sign-out-alt","to":_vm.localePath('/internal')},nativeOn:{"click":function($event){return _vm.authLogout()}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"fas fa-sign-out-alt","size":"is-small"}}),_vm._v("\n                            "+_vm._s(_vm.$t("actions.logout"))+"\n                        ")],1)],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"menu-body"},[_c('div',{staticClass:"menu-scroller"},[(this.mobile)?_c('span',{staticClass:"category-item"},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.scanner.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser'].firstName
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/validate'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/validate')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.memberships.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser']
                                .firstName &&
                            this.$store.getters['auth/authedUser']
                                .accessLevel > 0
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/ticketcheck'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/ticketcheck')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.tickets.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.mobile &&
                            this.$store.getters['auth/authedUser']
                                .firstName &&
                            this.$store.getters['auth/authedUser']
                                .accessLevel > 0
                        )?_c('span',{staticClass:"category-item"},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.manage.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser']
                                .firstName &&
                            this.$store.getters['auth/authedUser']
                                .accessLevel > 0
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/home'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/home')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.home.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser']
                                .firstName &&
                            this.$store.getters['auth/authedUser']
                                .accessLevel > 0
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/news'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/news')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.news.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser']
                                .firstName &&
                            this.$store.getters['auth/authedUser']
                                .accessLevel > 0
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/events'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/events')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.events.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser'].firstName
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal/list'
                                ? 'is-active'
                                : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal/list')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.members.header"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            this.$store.getters['auth/authedUser']
                                .firstName && !_vm.mobile
                        )?_c('b-navbar-item',{staticClass:"menu-item main",class:_vm.currentPage == '/internal' ? 'is-active' : '',attrs:{"tag":"router-link","to":_vm.localePath('/internal')},nativeOn:{"click":function($event){_vm.isActive ? _vm.animateMenuOut() : ''}}},[_vm._v("\n                        "+_vm._s(_vm.$t("categories.internal.dashboard.header"))+"\n                    ")]):_vm._e()],1),_vm._v(" "),(_vm.mobile)?_c('AppFooter',{attrs:{"onClickOut":_vm.animateMenuOut}}):_vm._e()],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }