<template>
  <div class="locale-picker">
    <b-dropdown
      append-to-body
      trap-focus
      aria-role="menu"
      position="is-top-left"
      :mobile-modal="false"
      class="i18n-dropdown"
    >
      <template #trigger>
        <a
          class="
            is-size-7
            has-text-weight-bold
            is-subtle is-uppercase is-rounded
          "
        >
          <b-icon icon="fas fa-globe" />
          {{ $i18n.locale }}
        </a>
      </template>

      <!-- <hr class="dropdown-divider" /> -->
      <b-dropdown-item
        class="dropdown-item is-uppercase is-rounded"
        v-for="locale in availableLocales"
        :key="locale.code"
        has-link
        aria-role="menuitem"
      >
        <nuxt-link
          class="is-small is-uppercase is-size-7 has-text-weight-bold"
          :to="switchLocalePath(locale.code)"
          >{{ locale.name }}</nuxt-link
        >
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "LocalePicker",
  components: {
    // IconSystem,
    // IconLight,
    // IconDark,
    // IconSepia,
  },
  data() {
    return {};
  },
  computed: {
    availableLocales() {
      // return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
      return this.$i18n.locales;
    },
  },
  // methods: {
  //   getClasses(color) {
  //     // Does not set classes on ssr preference is system (because we know them on client-side)
  //     if (this.$colorMode.unknown) {
  //       return {};
  //     }
  //     return {
  //       preferred: color === this.$colorMode.preference,
  //       selected: color === this.$colorMode.value,
  //     };
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.locale-picker {
  margin-bottom: 0.1rem;
}
</style>