<template>
    <div class="nav-container is-family-secondary glassy-bg">
        <!-- <div class="menu-backdrop-blur" /> -->
        <b-navbar
            :close-on-click="false"
            fixed-top
            :active.sync="isActive"
            class="is-transparent is-vcentered container has-text-link"
        >
            <template slot="brand">
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    class="brand-logo"
                    tag="router-link"
                    :to="localePath('/')"
                >
                    <!-- <b-button
                        as="a"
                        :class="{ 'is-active': isActive }"
                        @click="goBack()"
                        v-if="isIosPwaBackButton && canPressBack"
                        class="back-btn is-rounded is-subtle is-family-secondary"
                    >
                        <b-icon
                            icon-pack="fas"
                            class="burger-icon is-subtle"
                            icon="fas fa-arrow-left"
                        />
                    </b-button> -->
                    <Logo />
                    <span
                        v-if="mobile === false"
                        class="desktop-logo is-7 has-text-weight-bold is-uppercase"
                        :class="
                            currentPage == '' ? 'has-text-link' : 'is-subtle'
                        "
                        >Tempio Del Futuro *Perduto</span
                    >

                    <span
                        v-else
                        class="mt-1 is-7 has-text-weight-bold is-uppercase is-mobile-header"
                        :class="
                            currentPage == '' ? 'has-text-link' : 'is-subtle'
                        "
                        >{{
                            isActive
                                ? "TDF*P"
                                : this.$store.getters["getCurrPageTitle"]
                        }}</span
                    >
                </b-navbar-item>
            </template>
            <template slot="burger" v-if="mobile === true">
                <div class="burger-container">
                    <b-button
                        as="a"
                        :class="{ 'is-active': isActive }"
                        @click="!isActive ? animateMenuIn() : animateMenuOut()"
                        class="burger-btn is-subtle is-family-secondary"
                    >
                        <b-icon
                            icon-pack="fas"
                            class="burger-icon is-subtle"
                            :icon="isActive ? 'fas fa-times' : 'fas fa-user'"
                        />
                    </b-button>
                </div>
            </template>
            <template slot="end">
                <!-- <div class="glassy-bg-flat fixed" /> -->

                <div class="menu-body">
                    <div>
                        <div
                            class="card grid-item-xl"
                            v-if="
                                isActive &&
                                this.$store.getters['public-auth/authedUser']
                                    .firstName &&
                                mobile === true
                            "
                        >
                            <div class="card-content row-content">
                                <div class="content">
                                    <div
                                        class="is-size-3 has-text-primary-alt has-text-weight-bold mb-2"
                                    >
                                        {{
                                            this.$store.getters[
                                                "public-auth/authedUser"
                                            ].firstName
                                        }}
                                    </div>
                                </div>
                                <div class="content">
                                    <b-navbar-item
                                        @click.native="
                                            isActive ? animateMenuOut() : ''
                                        "
                                        tag="router-link"
                                        class="menu-item logged-menu-item"
                                        :class="
                                            currentPage == 'u'
                                                ? 'is-active'
                                                : ''
                                        "
                                        :to="localePath('/u')"
                                    >
                                        <b-icon
                                            icon="fas fa-user"
                                            size="is-small"
                                            class="mx-1"
                                        />
                                        {{ $t("profile.header") }}
                                    </b-navbar-item>
                                    <b-navbar-item
                                        @click.native="
                                            isActive ? animateMenuOut() : ''
                                        "
                                        tag="router-link"
                                        class="menu-item logged-menu-item is-subtle"
                                        :to="localePath('/u/manage')"
                                    >
                                        <b-icon
                                            icon="fas fa-cog"
                                            size="is-small"
                                            class="mx-1"
                                        />
                                        {{ $t("profile.manageAccount") }}
                                    </b-navbar-item>
                                    <b-navbar-item
                                        @click.native="authLogout()"
                                        tag="router-link"
                                        class="menu-item logged-menu-item logout-item"
                                        icon-right="fas fa-sign-out-alt"
                                        :class="
                                            currentPage == 'u'
                                                ? 'is-active'
                                                : ''
                                        "
                                        :to="localePath('/u')"
                                    >
                                        <b-icon
                                            icon="fas fa-sign-out-alt"
                                            size="is-small"
                                            class="mx-1"
                                        />
                                        {{ $t("actions.logout") }}
                                    </b-navbar-item>
                                </div>
                            </div>
                        </div>
                        <div class="menu-scroller">
                            <b-navbar-item
                                v-if="
                                    mobile === true &&
                                    // !loggedIn &&
                                    !this.$store.getters[
                                        'public-auth/authedUser'
                                    ].firstName
                                "
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="currentPage == 'u' ? 'is-active' : ''"
                                :to="localePath('/u')"
                            >
                                {{ $t("profile.header") }}
                            </b-navbar-item>

                            <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="
                                    currentPage == 'events' ? 'is-active' : ''
                                "
                                :to="localePath('events')"
                            >
                                {{ $t("events.header") }}
                            </b-navbar-item>
                            <!-- 
                        <b-navbar-item
                            @click.native="isActive ? animateMenuOut() : ''"
                            tag="router-link"
                            class="menu-item"
                            :class="currentPage == 'market' ? 'is-active' : ''"
                            :to="localePath('market')"
                        >
                            {{ $t("market.header") }}
                        </b-navbar-item> -->

                            <!-- <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="
                                    currentPage == 'virtuale' ? 'is-active' : ''
                                "
                                :to="localePath('virtuale')"
                            >
                                {{ $t("virtual.header") }}
                            </b-navbar-item> -->
                            <!-- <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="
                                    currentPage == 'sign' ? 'is-active' : ''
                                "
                                :to="localePath('sign')"
                            >
                                {{ $t("petition.header") }}
                            </b-navbar-item> -->

                            <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="
                                    currentPage == 'contribute'
                                        ? 'is-active'
                                        : ''
                                "
                                :to="localePath('contribute')"
                            >
                                {{ $t("contribute.header") }}
                            </b-navbar-item>

                            <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="a"
                                class="menu-item"
                                href="https://artsandculture.google.com/partner/tempio-del-futuro-perduto"
                                target="_blank"
                            >
                                {{ $t("exhibit.header") }}
                            </b-navbar-item>

                            <b-navbar-item
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item"
                                :class="
                                    currentPage == 'where' ? 'is-active' : ''
                                "
                                :to="localePath('where')"
                            >
                                {{ $t("where.header") }}
                            </b-navbar-item>

                            <b-navbar-item
                                v-if="
                                    mobile === false &&
                                    !this.$store.getters[
                                        'public-auth/authedUser'
                                    ].firstName
                                "
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item is-primary-menu-item"
                                :class="
                                    currentPage == 'where' ? 'is-active' : ''
                                "
                                :to="localePath('/u')"
                            >
                                <b-icon
                                    icon="fas fa-user"
                                    size="is-small"
                                    class="mr-1 mb-1 is-subtle"
                                />

                                {{ $t("profile.header") }}
                            </b-navbar-item>
                            <b-navbar-item
                                v-if="
                                    mobile === false &&
                                    this.$store.getters[
                                        'public-auth/authedUser'
                                    ].firstName
                                "
                                @click.native="isActive ? animateMenuOut() : ''"
                                tag="router-link"
                                class="menu-item is-primary-menu-item"
                                :class="
                                    currentPage == 'where' ? 'is-active' : ''
                                "
                                :to="localePath('/u')"
                            >
                                <b-icon
                                    icon="fas fa-user"
                                    size="is-small"
                                    class="mr-1 mb-1 is-primary"
                                />
                                {{
                                    this.$store.getters[
                                        "public-auth/authedUser"
                                    ].firstName
                                }}
                            </b-navbar-item>
                        </div>
                    </div>
                    <AppFooter :visible="mobile" :onClickOut="animateMenuOut" />
                </div>
            </template>
        </b-navbar>
    </div>
</template>
<style lang="scss" scoped>
.brand-logo {
    @media (min-width: 960px) {
        width: auto !important;
        margin: 0;
        padding: 0;
    }
}
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.logged-menu-item {
    margin: 0.5rem 0;
    padding: 0;
}
.logout-item {
    color: var(--color-danger) !important;
    * {
        color: var(--color-danger) !important;
    }
}
.burger-btn {
    background: none !important;
    background-color: none !important;
}
.card {
    background: var(--bg-secondary);
    border-radius: var(--radius-sm);
    height: auto;
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: initial;
    margin: 0 1.5rem 1rem 1.5rem;
}
.spacer {
    @media (min-width: 960px) {
        width: 0;
    }
    @media (max-width: 960px) {
        width: 30vw;
        background: var(--color-primary);
        height: 1px;
        display: block;
        min-width: 30vw;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        margin-left: 2rem;
    }
}

.menu-backdrop-blur {
    z-index: 0;
    pointer-events: none;
    position: absolute;
    top: 5em;
    height: calc(100vh);
    width: 100vw;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
}
.nav-container {
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;
    // left: 3px;
    height: 5rem;

    z-index: 10;
    /* transition: 220ms; */
    transition-timing-function: ease-in-out;
}
.navbar {
    height: 5rem;

    width: 100%;
    max-width: 1280px !important;
    top: 0;
    /* @media (max-width: 960px) and (orientation: landscape) { */
    @media (max-width: 1280px) and (min-width: 960px) {
        padding: 0 1rem;
    }
    .is-primary-menu-item {
        color: var(--color-primary-alt) !important;
    }
    .menu-item {
        text-transform: uppercase;
        font-weight: bold;
        @media (max-width: 960px) {
            font-size: 1.25em;
            background: none;
        }
    }
}
.burger-container {
    position: relative;
    height: 5rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5em;
    text-align: center;
    // @media (max-width: 960px) {
    //   display: block;
    // }
}
.burger-icon {
    background: none !important;
    background-color: none !important;
}
.brand-icon {
    height: 5rem;
    width: 3.5rem;
    object-fit: contain;

    // padding: 0 0.35rem;
    margin-right: 0.3em;
    @media (max-width: 960px) {
        // display: none;
    }
}
.navbar-item img {
    max-height: 3rem;
    margin-left: 0;
}
.back-btn {
    min-height: 3rem;
    height: 3rem;
    min-width: 3rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.v-icon {
    margin: 1rem 0.25rem 0 0.25rem;
    width: 100%;

    height: 100%;
}
.glassy-bg {
    /* background: rgba(var(--bg-secondary-rgba), 0.8); */
    background: linear-gradient(
        180deg,
        rgba(var(--bg-secondary-rgba), 1) 0%,
        rgba(var(--bg-secondary-rgba), 0.35) 100%
    );
    backdrop-filter: blur(10rem);
    -webkit-backdrop-filter: blur(10rem);
}
.glassy-bg-flat {
    z-index: 0;
    background: rgba(var(--bg-secondary-rgba), 0.8);
    backdrop-filter: blur(2rem) !important;
    -webkit-backdrop-filter: blur(2rem) !important;
}
.is-mobile-header {
    width: calc(100vw - 10rem);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
<script>
import Logo from "~/components/Logo";
import AppFooter from "~/components/AppFooter";
import * as Cookies from "js-cookie";
export default {
    name: "UserNavbar",
    // transition: "fade",
    components: { Logo, AppFooter },
    data() {
        return {
            isIosPwaBackButton: false,
            canPressBack: false,
            isActive: false,
            mobile: false,
            loggedIn: this.$store.getters["getLoggedInStatus"],
            // !mobile: this.checkIfMobile(),
            // loggedIn: this.$store.getters["getLoggedInStatus"],
        };
    },
    props: {
        isSolidNavbar: { type: Boolean, default: true, required: true },
        currentPage: { type: String, default: "", required: true },
    },
    mounted() {
        if (process.client && window && window.navigator) {
            const backButtonVisible =
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(window.navigator.platform) ||
                (window.navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document);
            this.canPressBack = this.canGoBack();
            this.isIosPwaBackButton = backButtonVisible;
            this.navReveal();
            // this.mobile = this.$store.getters["getIsMobile"];
            this.$nextTick(function () {
                this.mobile = this.$store.getters["getIsMobile"];
            });

            window.addEventListener("resize", this.handleResize);
        }
    },

    watch: {
        $route(to, from) {
            if (process.client && window && window.navigator) {
                this.canPressBack = this.canGoBack();
                const backButtonVisible =
                    [
                        "iPad Simulator",
                        "iPhone Simulator",
                        "iPod Simulator",
                        "iPad",
                        "iPhone",
                        "iPod",
                    ].includes(window.navigator.platform) ||
                    (window.navigator.userAgent.includes("Mac") &&
                        "ontouchend" in document);
                this.canPressBack = this.canGoBack();
                // console.log("can go back: " + this.canPressBack);
                this.isIosPwaBackButton = backButtonVisible;
            }
        },
    },
    computed: {
        isSolid() {
            return this.isSolidNavbar;
        },
        isCurrentPage() {
            return this.currentPage;
        },
    },
    methods: {
        handleResize() {
            this.mobile = this.$store.getters["getIsMobile"];
        },
        canGoBack() {
            // console.log("can go back: " + this.$router.history.current.name);
            let result = false;
            if (
                !this.$router.history.current.name ||
                !this.$router.history.current.name.startsWith("index")
            ) {
                result =
                    this.$router.history.current.name !== "internal-login" ||
                    this.$router.history.current.name !== "internal";
            } else {
                result = false;
            }
            // console.log("can go back: " + result);
            return result;
        },
        async authLogout() {
            this.animateMenuOut();
            try {
                const isDev = process.env.NODE_ENV !== "production";

                await this.$store.dispatch("public-auth/logout");
                await this.$store.dispatch("public-auth/refresh");
                // console.log("logout: " + logout);
                if (
                    this.$cookies &&
                    this.$cookies.get("authrefToken") &&
                    this.$cookies.get("authrefToken").length > 0
                ) {
                    Cookies.remove("authrefToken", {
                        path: "/",
                        domain: isDev
                            ? "localhost"
                            : process.env.BROWSER_BASE_URL,
                    });
                }
                // refresh the page
                this.$router.push(this.localePath("/u/login"));
                this.$store.dispatch("setLoggedIn", false);

                // this.$router.go();
            } catch (e) {
                console.error(e);
            } finally {
            }
        },
        animateMenuIn() {
            const anime = this.$anime;
            anime({
                targets: ".burger-icon",
                // translateY: [-20, 0],
                // height: ["40vh", "50vh"],
                opacity: [1, 0, 1],
                easing: "easeInOutCirc",
                // delay: 100,
                duration: 350,
            });

            setTimeout(() => {
                this.isActive = true;
            }, 250);
            anime({
                targets: ".navbar-menu",
                translateY: [-20, 0],
                height: ["80vh", "100vh"],

                opacity: [0, 1],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
            anime({
                targets: ".menu-backdrop-blur",
                opacity: [0, 1],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
        },
        animateMenuOut() {
            const anime = this.$anime;
            anime({
                targets: ".menu-backdrop-blur",
                opacity: [1, 0],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
            anime({
                targets: ".burger-icon",
                // translateY: [-20, 0],
                // height: ["40vh", "50vh"],
                opacity: [1, 0, 1],
                easing: "easeInOutCirc",
                // delay: 100,
                duration: 350,
            });
            anime({
                targets: ".navbar-menu",
                translateY: [0, -20],
                height: ["100vh", "80vh"],
                opacity: [1, 0],
                easing: "easeInOutCirc",
                duration: 250,
            });
            setTimeout(() => {
                this.isActive = false;
            }, 250);
        },

        goBack() {
            this.$router.go(-1);
        },
        navReveal() {
            const anime = this.$anime;

            anime({
                targets: ".brand-icon",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 120,
                duration: 250,
            });
            anime({
                translateY: 100,
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 250,
                duration: 250,
            });
            anime({
                targets: ".desktop-logo",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 325,
                duration: 250,
            });
            anime({
                targets: ".menu-item",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: anime.stagger(100, { start: 450 }),
                duration: 250,
            });
        },
    },
};
</script>
