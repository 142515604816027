import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _58dc95b4 = () => interopDefault(import('../src/pages/contribute.vue' /* webpackChunkName: "pages/contribute" */))
const _2da7ee50 = () => interopDefault(import('../src/pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _28f6aeee = () => interopDefault(import('../src/pages/cookies-en.vue' /* webpackChunkName: "pages/cookies-en" */))
const _dd6fa44a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ace67c40 = () => interopDefault(import('../src/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _1ccd6947 = () => interopDefault(import('../src/pages/internal/index.vue' /* webpackChunkName: "pages/internal/index" */))
const _793baff7 = () => interopDefault(import('../src/pages/marketold/index.vue' /* webpackChunkName: "pages/marketold/index" */))
const _af1e755e = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _48df73d5 = () => interopDefault(import('../src/pages/privacy-en.vue' /* webpackChunkName: "pages/privacy-en" */))
const _123b7f78 = () => interopDefault(import('../src/pages/refunds.vue' /* webpackChunkName: "pages/refunds" */))
const _5e9b9ffc = () => interopDefault(import('../src/pages/refunds-en.vue' /* webpackChunkName: "pages/refunds-en" */))
const _3b45ec10 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _5b8760b6 = () => interopDefault(import('../src/pages/terms-en.vue' /* webpackChunkName: "pages/terms-en" */))
const _0746a008 = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _04b48c81 = () => interopDefault(import('../src/pages/u/index.vue' /* webpackChunkName: "pages/u/index" */))
const _f06ea120 = () => interopDefault(import('../src/pages/where.vue' /* webpackChunkName: "pages/where" */))
const _e2a21818 = () => interopDefault(import('../src/pages/internal/events.vue' /* webpackChunkName: "pages/internal/events" */))
const _6991097a = () => interopDefault(import('../src/pages/internal/home.vue' /* webpackChunkName: "pages/internal/home" */))
const _527e648e = () => interopDefault(import('../src/pages/internal/list.vue' /* webpackChunkName: "pages/internal/list" */))
const _3315e744 = () => interopDefault(import('../src/pages/internal/login.vue' /* webpackChunkName: "pages/internal/login" */))
const _d98a4a9e = () => interopDefault(import('../src/pages/internal/membership.vue' /* webpackChunkName: "pages/internal/membership" */))
const _05f1c171 = () => interopDefault(import('../src/pages/internal/news/index.vue' /* webpackChunkName: "pages/internal/news/index" */))
const _fb11f99e = () => interopDefault(import('../src/pages/internal/ticketcheck.vue' /* webpackChunkName: "pages/internal/ticketcheck" */))
const _18d07e9e = () => interopDefault(import('../src/pages/internal/validate.vue' /* webpackChunkName: "pages/internal/validate" */))
const _39d76bae = () => interopDefault(import('../src/pages/marketold/calendar.vue' /* webpackChunkName: "pages/marketold/calendar" */))
const _76920518 = () => interopDefault(import('../src/pages/marketold/contribute.vue' /* webpackChunkName: "pages/marketold/contribute" */))
const _6ad35918 = () => interopDefault(import('../src/pages/u/forgot-password.vue' /* webpackChunkName: "pages/u/forgot-password" */))
const _6347a0d0 = () => interopDefault(import('../src/pages/u/login.vue' /* webpackChunkName: "pages/u/login" */))
const _06c02374 = () => interopDefault(import('../src/pages/u/manage.vue' /* webpackChunkName: "pages/u/manage" */))
const _bf6aa892 = () => interopDefault(import('../src/pages/u/membership.vue' /* webpackChunkName: "pages/u/membership" */))
const _1817a244 = () => interopDefault(import('../src/pages/u/register.vue' /* webpackChunkName: "pages/u/register" */))
const _55bb4a83 = () => interopDefault(import('../src/pages/u/confirm/activate.vue' /* webpackChunkName: "pages/u/confirm/activate" */))
const _418c5bc5 = () => interopDefault(import('../src/pages/u/confirm/expired.vue' /* webpackChunkName: "pages/u/confirm/expired" */))
const _091154ba = () => interopDefault(import('../src/pages/u/confirm/success.vue' /* webpackChunkName: "pages/u/confirm/success" */))
const _1e7988d8 = () => interopDefault(import('../src/pages/u/confirm/trigger.vue' /* webpackChunkName: "pages/u/confirm/trigger" */))
const _de482342 = () => interopDefault(import('../src/pages/u/recover/activate.vue' /* webpackChunkName: "pages/u/recover/activate" */))
const _4fcc672e = () => interopDefault(import('../src/pages/u/recover/expired.vue' /* webpackChunkName: "pages/u/recover/expired" */))
const _dbf67372 = () => interopDefault(import('../src/pages/u/recover/success.vue' /* webpackChunkName: "pages/u/recover/success" */))
const _4ab6f03d = () => interopDefault(import('../src/pages/internal/event/_id/index.vue' /* webpackChunkName: "pages/internal/event/_id/index" */))
const _55336c19 = () => interopDefault(import('../src/pages/internal/news/_id.vue' /* webpackChunkName: "pages/internal/news/_id" */))
const _0a3b29eb = () => interopDefault(import('../src/pages/internal/view/_id.vue' /* webpackChunkName: "pages/internal/view/_id" */))
const _3447858c = () => interopDefault(import('../src/pages/u/confirm/_confirmId.vue' /* webpackChunkName: "pages/u/confirm/_confirmId" */))
const _29cea268 = () => interopDefault(import('../src/pages/u/recover/_confirmId.vue' /* webpackChunkName: "pages/u/recover/_confirmId" */))
const _32ee1180 = () => interopDefault(import('../src/pages/internal/event/_id/lists.vue' /* webpackChunkName: "pages/internal/event/_id/lists" */))
const _242117ce = () => interopDefault(import('../src/pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _26ccb014 = () => interopDefault(import('../src/pages/internal/_404.vue' /* webpackChunkName: "pages/internal/_404" */))
const _2fd56f05 = () => interopDefault(import('../src/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _279184cc = () => interopDefault(import('../src/pages/u/_404.vue' /* webpackChunkName: "pages/u/_404" */))
const _645d0900 = () => interopDefault(import('../src/pages/_404.vue' /* webpackChunkName: "pages/_404" */))
const _12d4c4f3 = () => interopDefault(import('../src/pages/_oldindex.vue' /* webpackChunkName: "pages/_oldindex" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contribute",
    component: _58dc95b4,
    name: "contribute___it"
  }, {
    path: "/cookies",
    component: _2da7ee50,
    name: "cookies___it"
  }, {
    path: "/cookies-en",
    component: _28f6aeee,
    name: "cookies-en___it"
  }, {
    path: "/en",
    component: _dd6fa44a,
    name: "index___en"
  }, {
    path: "/events",
    component: _ace67c40,
    name: "events___it"
  }, {
    path: "/internal",
    component: _1ccd6947,
    name: "internal___it"
  }, {
    path: "/marketold",
    component: _793baff7,
    name: "marketold___it"
  }, {
    path: "/privacy",
    component: _af1e755e,
    name: "privacy___it"
  }, {
    path: "/privacy-en",
    component: _48df73d5,
    name: "privacy-en___it"
  }, {
    path: "/refunds",
    component: _123b7f78,
    name: "refunds___it"
  }, {
    path: "/refunds-en",
    component: _5e9b9ffc,
    name: "refunds-en___it"
  }, {
    path: "/terms",
    component: _3b45ec10,
    name: "terms___it"
  }, {
    path: "/terms-en",
    component: _5b8760b6,
    name: "terms-en___it"
  }, {
    path: "/thankyou",
    component: _0746a008,
    name: "thankyou___it"
  }, {
    path: "/u",
    component: _04b48c81,
    name: "u___it"
  }, {
    path: "/where",
    component: _f06ea120,
    name: "where___it"
  }, {
    path: "/en/contribute",
    component: _58dc95b4,
    name: "contribute___en"
  }, {
    path: "/en/cookies",
    component: _2da7ee50,
    name: "cookies___en"
  }, {
    path: "/en/cookies-en",
    component: _28f6aeee,
    name: "cookies-en___en"
  }, {
    path: "/en/events",
    component: _ace67c40,
    name: "events___en"
  }, {
    path: "/en/internal",
    component: _1ccd6947,
    name: "internal___en"
  }, {
    path: "/en/marketold",
    component: _793baff7,
    name: "marketold___en"
  }, {
    path: "/en/privacy",
    component: _af1e755e,
    name: "privacy___en"
  }, {
    path: "/en/privacy-en",
    component: _48df73d5,
    name: "privacy-en___en"
  }, {
    path: "/en/refunds",
    component: _123b7f78,
    name: "refunds___en"
  }, {
    path: "/en/refunds-en",
    component: _5e9b9ffc,
    name: "refunds-en___en"
  }, {
    path: "/en/terms",
    component: _3b45ec10,
    name: "terms___en"
  }, {
    path: "/en/terms-en",
    component: _5b8760b6,
    name: "terms-en___en"
  }, {
    path: "/en/thankyou",
    component: _0746a008,
    name: "thankyou___en"
  }, {
    path: "/en/u",
    component: _04b48c81,
    name: "u___en"
  }, {
    path: "/en/where",
    component: _f06ea120,
    name: "where___en"
  }, {
    path: "/internal/events",
    component: _e2a21818,
    name: "internal-events___it"
  }, {
    path: "/internal/home",
    component: _6991097a,
    name: "internal-home___it"
  }, {
    path: "/internal/list",
    component: _527e648e,
    name: "internal-list___it"
  }, {
    path: "/internal/login",
    component: _3315e744,
    name: "internal-login___it"
  }, {
    path: "/internal/membership",
    component: _d98a4a9e,
    name: "internal-membership___it"
  }, {
    path: "/internal/news",
    component: _05f1c171,
    name: "internal-news___it"
  }, {
    path: "/internal/ticketcheck",
    component: _fb11f99e,
    name: "internal-ticketcheck___it"
  }, {
    path: "/internal/validate",
    component: _18d07e9e,
    name: "internal-validate___it"
  }, {
    path: "/marketold/calendar",
    component: _39d76bae,
    name: "marketold-calendar___it"
  }, {
    path: "/marketold/contribute",
    component: _76920518,
    name: "marketold-contribute___it"
  }, {
    path: "/u/forgot-password",
    component: _6ad35918,
    name: "u-forgot-password___it"
  }, {
    path: "/u/login",
    component: _6347a0d0,
    name: "u-login___it"
  }, {
    path: "/u/manage",
    component: _06c02374,
    name: "u-manage___it"
  }, {
    path: "/u/membership",
    component: _bf6aa892,
    name: "u-membership___it"
  }, {
    path: "/u/register",
    component: _1817a244,
    name: "u-register___it"
  }, {
    path: "/en/internal/events",
    component: _e2a21818,
    name: "internal-events___en"
  }, {
    path: "/en/internal/home",
    component: _6991097a,
    name: "internal-home___en"
  }, {
    path: "/en/internal/list",
    component: _527e648e,
    name: "internal-list___en"
  }, {
    path: "/en/internal/login",
    component: _3315e744,
    name: "internal-login___en"
  }, {
    path: "/en/internal/membership",
    component: _d98a4a9e,
    name: "internal-membership___en"
  }, {
    path: "/en/internal/news",
    component: _05f1c171,
    name: "internal-news___en"
  }, {
    path: "/en/internal/ticketcheck",
    component: _fb11f99e,
    name: "internal-ticketcheck___en"
  }, {
    path: "/en/internal/validate",
    component: _18d07e9e,
    name: "internal-validate___en"
  }, {
    path: "/en/marketold/calendar",
    component: _39d76bae,
    name: "marketold-calendar___en"
  }, {
    path: "/en/marketold/contribute",
    component: _76920518,
    name: "marketold-contribute___en"
  }, {
    path: "/en/u/forgot-password",
    component: _6ad35918,
    name: "u-forgot-password___en"
  }, {
    path: "/en/u/login",
    component: _6347a0d0,
    name: "u-login___en"
  }, {
    path: "/en/u/manage",
    component: _06c02374,
    name: "u-manage___en"
  }, {
    path: "/en/u/membership",
    component: _bf6aa892,
    name: "u-membership___en"
  }, {
    path: "/en/u/register",
    component: _1817a244,
    name: "u-register___en"
  }, {
    path: "/u/confirm/activate",
    component: _55bb4a83,
    name: "u-confirm-activate___it"
  }, {
    path: "/u/confirm/expired",
    component: _418c5bc5,
    name: "u-confirm-expired___it"
  }, {
    path: "/u/confirm/success",
    component: _091154ba,
    name: "u-confirm-success___it"
  }, {
    path: "/u/confirm/trigger",
    component: _1e7988d8,
    name: "u-confirm-trigger___it"
  }, {
    path: "/u/recover/activate",
    component: _de482342,
    name: "u-recover-activate___it"
  }, {
    path: "/u/recover/expired",
    component: _4fcc672e,
    name: "u-recover-expired___it"
  }, {
    path: "/u/recover/success",
    component: _dbf67372,
    name: "u-recover-success___it"
  }, {
    path: "/en/u/confirm/activate",
    component: _55bb4a83,
    name: "u-confirm-activate___en"
  }, {
    path: "/en/u/confirm/expired",
    component: _418c5bc5,
    name: "u-confirm-expired___en"
  }, {
    path: "/en/u/confirm/success",
    component: _091154ba,
    name: "u-confirm-success___en"
  }, {
    path: "/en/u/confirm/trigger",
    component: _1e7988d8,
    name: "u-confirm-trigger___en"
  }, {
    path: "/en/u/recover/activate",
    component: _de482342,
    name: "u-recover-activate___en"
  }, {
    path: "/en/u/recover/expired",
    component: _4fcc672e,
    name: "u-recover-expired___en"
  }, {
    path: "/en/u/recover/success",
    component: _dbf67372,
    name: "u-recover-success___en"
  }, {
    path: "/",
    component: _dd6fa44a,
    name: "index___it"
  }, {
    path: "/en/internal/event/:id",
    component: _4ab6f03d,
    name: "internal-event-id___en"
  }, {
    path: "/en/internal/news/:id",
    component: _55336c19,
    name: "internal-news-id___en"
  }, {
    path: "/en/internal/view/:id?",
    component: _0a3b29eb,
    name: "internal-view-id___en"
  }, {
    path: "/en/u/confirm/:confirmId?",
    component: _3447858c,
    name: "u-confirm-confirmId___en"
  }, {
    path: "/en/u/recover/:confirmId?",
    component: _29cea268,
    name: "u-recover-confirmId___en"
  }, {
    path: "/en/internal/event/:id?/lists",
    component: _32ee1180,
    name: "internal-event-id-lists___en"
  }, {
    path: "/en/event/:id?",
    component: _242117ce,
    name: "event-id___en"
  }, {
    path: "/en/internal/:404",
    component: _26ccb014,
    name: "internal-404___en"
  }, {
    path: "/en/news/:id?",
    component: _2fd56f05,
    name: "news-id___en"
  }, {
    path: "/en/u/:404",
    component: _279184cc,
    name: "u-404___en"
  }, {
    path: "/internal/event/:id",
    component: _4ab6f03d,
    name: "internal-event-id___it"
  }, {
    path: "/internal/news/:id",
    component: _55336c19,
    name: "internal-news-id___it"
  }, {
    path: "/internal/view/:id?",
    component: _0a3b29eb,
    name: "internal-view-id___it"
  }, {
    path: "/u/confirm/:confirmId?",
    component: _3447858c,
    name: "u-confirm-confirmId___it"
  }, {
    path: "/u/recover/:confirmId?",
    component: _29cea268,
    name: "u-recover-confirmId___it"
  }, {
    path: "/internal/event/:id?/lists",
    component: _32ee1180,
    name: "internal-event-id-lists___it"
  }, {
    path: "/en/:404",
    component: _645d0900,
    name: "404___en"
  }, {
    path: "/en/:oldindex",
    component: _12d4c4f3,
    name: "oldindex___en"
  }, {
    path: "/event/:id?",
    component: _242117ce,
    name: "event-id___it"
  }, {
    path: "/internal/:404",
    component: _26ccb014,
    name: "internal-404___it"
  }, {
    path: "/news/:id?",
    component: _2fd56f05,
    name: "news-id___it"
  }, {
    path: "/u/:404",
    component: _279184cc,
    name: "u-404___it"
  }, {
    path: "/:404",
    component: _645d0900,
    name: "404___it"
  }, {
    path: "/:oldindex",
    component: _12d4c4f3,
    name: "oldindex___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
