// store/public-auth.js
import confirmMailBody from "~/assets/mailtrap-template/confirmCreationTemplate.js";
import pwdRecoverBody from "~/assets/mailtrap-template/pwdRecoverTemplate.js";
// // reusable aliases for mutations
export const AUTH_MUTATIONS = {
    SET_USER: "SET_USER",
    SET_PAYLOAD: "SET_PAYLOAD",
    LOGOUT: "LOGOUT",
};

export const state = () => ({
    // access_token: null, // JWT access token
    refToken: null, // JWT refresh token
    id: null, // user id
    email: null, // user email address
    firstName: null,
    lastName: null,
});

export const mutations = {
    //     // store the logged in user in the state
    [AUTH_MUTATIONS.SET_USER](
        state,
        { id, email, token, firstName, lastName }
    ) {
        state.id = id;
        state.email = email;
        state.refToken = token;
        state.firstName = firstName;
        state.lastName = lastName;
    },

    // store new or updated token fields in the state
    [AUTH_MUTATIONS.SET_PAYLOAD](
        state,
        { id, email, refToken = null, firstName, lastName }
    ) {
        // state.access_token = access_token;
        if (id) {
            state.id = id;
        }
        if (email) {
            state.email = email;
        }
        // refresh token is optional, only set it if present
        if (refToken) {
            state.refToken = refToken;
        }

        // if the user is logged in, set the Authorization header with the new token
        if (state.access_token) {
            this.$axios.setToken(state.access_token, "Bearer");
        }

        // if the user is logged in, set the Authorization header with the new token
        if (state.refToken) {
            this.$axios.setToken(state.refToken, "Bearer");
        }

        if (state.firstName) {
            state.firstName = firstName;
        }

        if (state.lastName) {
            state.lastName = lastName;
        }
    },

    // clear our the state, essentially logging out the user
    [AUTH_MUTATIONS.LOGOUT](state) {
        state.id = null;
        state.email = null;
        state.firstName = null;
        state.lastName = null;
        // state.access_token = null;
        if (state.refToken) {
            state.refToken = null;
        }
    },
};

const AUTH_PUB_USER = `
    mutation authenticate($email: String!, $pass: String!) {
        authenticatePublicUtenteWithPassword(email: $email, password: $pass) {
          token
          item {
            active
            email
            id
            firstName
            lastName
          }
        }
      }
`;

const REGISTER_PUB_USER = `
    mutation register($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
        createPublicUtente(
            data: {
                email: $email
                password: $password
                firstName: $firstName
                lastName: $lastName
            }
        ) {
            email
            id
            firstName
            lastName
        }
    }
`;
const CHECK_EMAIL_PUB_USER = `
    query check($email: String!) {
        allPublicUtenti(where: { email: $email }) {
            id
            email
            firstName
            lastName
        }
    }
`;
const DEAUTH_PUB_USER = `
    mutation unauthenticate {
        unauthenticatePublicUtente {
          success
        }
      }
`;
const CHECK_PUB_USER = `
    query check {
        authenticatedPublicUtente {
            id  
            email
            firstName
            lastName
        }
      }
`;

const CREATE_ACC_REQUEST = `
        mutation createAccountCreateRequest($token: String!) {
            createAccountCreateRequest(data: {token: $token}) {
                token
                expiresAt
                id
            }
        }
    `;
const RECOVER_PWD_REQUEST = `
        mutation createPasswordRecoverRequest($token: String!) {
            createPasswordRecoverRequest(data: {token: $token}) {
                token
                expiresAt
                id
            }
        }
    `;
const graphql = async (query, variables) => {
    const res = await fetch(
        process.env.NODE_ENV === "production"
            ? "https://www.tempiodelfuturo.art/admin/api"
            : "http://localhost:3000/admin/api",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query,
                variables,
            }),
        }
    );
    return await res.json();
};

export const actions = {
    async login({ commit, dispatch }, { email_address, password }) {
        // make an API call to login the user with an email address and password

        try {
            const { data } = await graphql(AUTH_PUB_USER, {
                email: email_address,
                pass: password,
            });

            if (data && data.authenticatePublicUtenteWithPassword) {
                if (
                    !data.authenticatePublicUtenteWithPassword.item.active ||
                    data.authenticatePublicUtenteWithPassword.item.active ===
                        false
                ) {
                    // commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                    //     id: null,
                    //     email: null,
                    //     refToken: null,
                    //     firstName: null,
                    //     lastName: null,
                    // });
                    //TODO: da sistemare
                    this.$router.push(this.localePath("u/confirm/trigger"));
                }

                commit(AUTH_MUTATIONS.SET_USER, {
                    id: data.authenticatePublicUtenteWithPassword.item.id,
                    email: data.authenticatePublicUtenteWithPassword.item.email,
                    token: data.authenticatePublicUtenteWithPassword.token,
                    firstName:
                        data.authenticatePublicUtenteWithPassword.item
                            .firstName,
                    lastName:
                        data.authenticatePublicUtenteWithPassword.item.lastName,
                });
            } else {
                commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                    id: null,
                    email: null,
                    refToken: null,
                    firstName: null,
                    lastName: null,
                });
                // catch any errors and automatically logout the user
                await dispatch("public-auth/logout");
            }
        } catch (error) {
            console.log(error);
        }
        // // commit the user and tokens to the state
        // commit(AUTH_MUTATIONS.SET_PAYLOAD, payload);
    },

    async register(
        { commit, dispatch },
        { firstName, lastName, email_address, password }
    ) {
        // make an API call to register the user
        try {
            // send confirmation email
            const check = await graphql(CHECK_EMAIL_PUB_USER, {
                email: email_address,
            });

            if (
                check.data &&
                check.data.allPublicUtenti &&
                check.data.allPublicUtenti.length > 0
            ) {
                // console.log("user already exists");
                return {
                    data: null,
                    errors: [
                        {
                            message: "E11000",
                        },
                    ],
                };
            } else {
                const timestamp = new Date().getTime();
                const formattedDate = new Date(timestamp).toLocaleString(
                    "it-IT",
                    {
                        timeZone: "Europe/Rome",
                    }
                );
                // console.log("REQUEST", requestData);
                const key = `${timestamp},${email_address}`;
                const encKey = $nuxt.$CryptoJS.AES.encrypt(
                    key,
                    $nuxt.$config.accConfirmSecret
                ).toString();
                const keyEncToBase = window.btoa(encKey);
                // console.log("CHECK", check);
                const { data: requestData } = await graphql(
                    CREATE_ACC_REQUEST,
                    {
                        token: keyEncToBase,
                    }
                );
                if (requestData && requestData.createAccountCreateRequest) {
                    const mailKey = `${requestData.createAccountCreateRequest.id},${requestData.createAccountCreateRequest.token}`;
                    const mailKeyEncToBase = window.btoa(mailKey);

                    const mail = await this.$axios
                        .$post(
                            process.env.NODE_ENV === "production"
                                ? "https://www.tempiodelfuturo.art/api/notify"
                                : "http://localhost:3000/api/notify",
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                to: `${firstName} ${lastName} <${email_address}>`,
                                subject: "Attiva il tuo account",
                                text: `[TEMPIO DEL FUTURO *PERDUTO]
                            https://www.tempiodelfuturo.art/

                            Attiva il tuo account

                            ${formattedDate}

                            Ciao ${firstName},

                            puoi completare la procedura di creazione del tuo account
                            [cliccando qui]
                            ${
                                this.$config.publicUrl
                            }/u/confirm/${mailKeyEncToBase}

                            Il link ha una validità di 15 minuti.

                            Se non hai richiesto questa modifica, ti preghiamo di

                            [contattarci]
                            mailto:hello@tempiodelfuturo.art?subject=Richiesta%20erronea%20creazione%20account

                            TDF*P ${new Date().getFullYear()}

                            Questa è una e-mail automatizzata. Non rispondere a questo messaggio.

                            Facebook

                            Instagram

                            [PRIVACY]
                            https://www.tempiodelfuturo.art/privacy`,
                                html: confirmMailBody(
                                    firstName,
                                    formattedDate,
                                    this.$config.publicUrl,
                                    mailKeyEncToBase
                                ),
                            }
                        )
                        .then(async (res) => {
                            // registree user if email sent
                            if (!res || res !== "OK") {
                                return {
                                    data: null,
                                    errors: [
                                        {
                                            message: "E999",
                                        },
                                    ],
                                };
                            }

                            const response = await graphql(REGISTER_PUB_USER, {
                                firstName: firstName,
                                lastName: lastName,
                                email: email_address,
                                password: password,
                            });

                            // console.log("RESULT", response);

                            return response;
                        })
                        .catch((err) => {
                            console.log(err);

                            return {
                                data: null,
                                errors: [
                                    {
                                        message: "E999",
                                    },
                                ],
                            };
                        });

                    // console.log("MAIL", mail);
                    if (
                        mail &&
                        mail.data &&
                        mail.data.createPublicUtente !== null
                    ) {
                        // console.log("OK");
                        // console.log(mail.data.createPublicUtente);
                        return {
                            data: mail.data.createPublicUtente,
                            errors: null,
                        };
                    } else if (mail && mail.errors) {
                        // console.log("dupe email");
                        // console.log(mail.errors);
                        return { data: null, errors: mail.errors };
                    } else {
                        return {
                            data: null,
                            errors: [
                                {
                                    message: "E998",
                                },
                            ],
                        };
                    }
                } else {
                    return {
                        data: null,
                        errors: [
                            {
                                message: "E997",
                            },
                        ],
                    };
                }
            }
        } catch (error) {
            console.log(error);
        }
    },
    async recover({ commit, dispatch }, { email_address }) {
        // make an API call to register the user
        try {
            // send confirmation email
            const recover = await this.$axios
                .$post(
                    process.env.NODE_ENV === "production"
                        ? "https://www.tempiodelfuturo.art/api/password-recover"
                        : "http://localhost:3000/api/password-recover",
                    {
                        email: email_address,
                    }
                )
                .then(async (res) => {
                    // registree user if email sent
                    if (!res || res !== "OK") {
                        return {
                            data: null,
                            errors: [
                                {
                                    message: "E999",
                                },
                            ],
                        };
                    } else {
                        // console.log("SENT");
                        return {
                            data: res,
                            errors: null,
                        };
                    }
                })
                .catch((err) => {
                    console.log(err);

                    return {
                        data: null,
                        errors: [
                            {
                                message: "E999",
                            },
                        ],
                    };
                });

            if (recover && recover.data) {
                return {
                    data: recover.data,
                    errors: null,
                };
            } else if (recover && recover.errors) {
                return {
                    data: null,
                    errors: recover.errors,
                };
            } else {
                return {
                    data: null,
                    errors: [
                        {
                            message: "E998",
                        },
                    ],
                };
            }
        } catch (error) {
            console.log(error);
        }
    },
    async confirmaccount({ commit, dispatch }) {
        // make an API call to register the user
        try {
            // send confirmation email
            const check = await graphql(CHECK_PUB_USER);

            if (check.data && check.data.authenticatedPublicUtente) {
                // console.log("user already exists");

                const user = check.data.authenticatedPublicUtente;
                const timestamp = new Date().getTime();
                const formattedDate = new Date(timestamp).toLocaleString(
                    "it-IT",
                    {
                        timeZone: "Europe/Rome",
                    }
                );
                const key = `${timestamp},${user.email}`;
                const encKey = $nuxt.$CryptoJS.AES.encrypt(
                    key,
                    $nuxt.$config.accConfirmSecret
                ).toString();
                const keyEncToBase = window.btoa(encKey);
                // console.log("CHECK", check);
                const { data: requestData } = await graphql(
                    CREATE_ACC_REQUEST,
                    {
                        token: keyEncToBase,
                    }
                );
                // console.log("REQUEST", requestData);
                if (requestData && requestData.createAccountCreateRequest) {
                    const mailKey = `${requestData.createAccountCreateRequest.id},${requestData.createAccountCreateRequest.token}`;
                    const mailKeyEncToBase = window.btoa(mailKey);

                    const mail = await this.$axios
                        .$post(
                            process.env.NODE_ENV === "production"
                                ? "https://www.tempiodelfuturo.art/api/notify"
                                : "http://localhost:3000/api/notify",
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                to: `${user.firstName} ${user.lastName} <${user.email}>`,
                                subject: "Attiva il tuo account",
                                text: `[TEMPIO DEL FUTURO *PERDUTO]
                            https://www.tempiodelfuturo.art/

                            Attiva il tuo account

                            ${formattedDate}

                            Ciao ${user.firstName},

                            puoi completare la procedura di creazione del tuo account
                            [cliccando qui]
                            ${
                                this.$config.publicUrl
                            }/u/confirm/${mailKeyEncToBase}

                            Il link ha una validità di 15 minuti.

                            Se non hai richiesto questa modifica, ti preghiamo di

                            [contattarci]
                            mailto:hello@tempiodelfuturo.art?subject=Richiesta%20erronea%20creazione%20account

                            TDF*P ${new Date().getFullYear()}

                            Questa è una e-mail automatizzata. Non rispondere a questo messaggio.

                            Facebook

                            Instagram

                            [PRIVACY]
                            https://www.tempiodelfuturo.art/privacy`,
                                html: confirmMailBody(
                                    user.firstName,
                                    formattedDate,
                                    this.$config.publicUrl,
                                    mailKeyEncToBase
                                ),
                            }
                        )
                        .then(async (res) => {
                            // registree user if email sent
                            if (!res || res !== "OK") {
                                // console.log("E999");
                                return {
                                    data: null,
                                    errors: [
                                        {
                                            message: "E999",
                                        },
                                    ],
                                };
                            } else {
                                // console.log("OK");
                                return {
                                    data: "OK",
                                    errors: null,
                                };
                            }
                        })
                        .catch((err) => {
                            console.log(err);

                            return {
                                data: null,
                                errors: [
                                    {
                                        message: "E999",
                                    },
                                ],
                            };
                        });

                    // console.log("MAIL", mail);
                    if (
                        mail &&
                        mail.data &&
                        mail.data.createPublicUtente !== null
                    ) {
                        // console.log("OK");
                        // console.log(mail.data.createPublicUtente);
                        return {
                            data: mail.data.createPublicUtente,
                            errors: null,
                        };
                    } else if (mail && mail.errors) {
                        // console.log("dupe email");
                        // console.log(mail.errors);
                        return { data: null, errors: mail.errors };
                    } else {
                        return {
                            data: null,
                            errors: [
                                {
                                    message: "E998",
                                },
                            ],
                        };
                    }
                } else {
                    return {
                        data: null,
                        errors: [
                            {
                                message: "E997",
                            },
                        ],
                    };
                }
            }
        } catch (error) {
            console.log(error);
        }
    },
    // given the current refresh token, refresh the user's access token to prevent expiry
    async refresh({ commit, state }) {
        const { refToken } = state;
        const { data } = await graphql(CHECK_PUB_USER, {
            token: refToken,
        });

        if (
            data &&
            data.authenticatedPublicUtente &&
            data.authenticatedPublicUtente.id
        ) {
            // console.log("AUTHED", data);

            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: data.authenticatedPublicUtente.id,
                email: data.authenticatedPublicUtente.email,
                refToken: refToken,
                firstName: data.authenticatedPublicUtente.firstName,
                lastName: data.authenticatedPublicUtente.lastName,
            });
        } else {
            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: null,
                email: null,
                refToken: null,
                firstName: null,
                lastName: null,
            });
            // catch any errors and automatically logout the user
            commit(AUTH_MUTATIONS.LOGOUT);
        }
    },
    // logout the user
    async logout({ commit, state }) {
        const { success } = await graphql(DEAUTH_PUB_USER, {
            token: state.refToken,
        });

        if (success) {
            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: null,
                email: null,
                refToken: null,
                firstName: null,
                lastName: null,
            });
            commit(AUTH_MUTATIONS.LOGOUT);
        }
    },
};

export const getters = {
    // determine if the user is authenticated based on the presence of the access token
    isAuthenticated: (state) => {
        return (
            state.id &&
            state.id !== "" &&
            state.email &&
            state.email !== "" &&
            state.refToken &&
            state.refToken !== "" &&
            state.firstName &&
            state.firstName !== "" &&
            state.lastName &&
            state.lastName !== ""
        );
    },
    authedUser: (state) => {
        return {
            id: state.id,
            email: state.email,
            refToken: state.refToken,
            firstName: state.firstName,
            lastName: state.lastName,
        };
    },

    token: (state) => {
        return state.refToken;
    },
};
