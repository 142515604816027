import Vue from "vue";
import VueVideoPlayer from "vue-video-player-vjs7/dist/ssr";
// import hlsQualitySelector from "videojs-hls-quality-selector";

// import VimePlayer from "@vime/vue";
// import VimeVideo from "@vime/vue";
// import Vime from "@vime/vue";

// const VueVideoPlayer = require("vue-video-player/dist/ssr");

// import qualityLevels from "videojs-contrib-quality-levels";

// const VueVideoPlayer = require("vue-vjs-hls");
// global.videojs = videojs;
// global.hls = hls;
// VueVideoPlayer.hlsQualitySelector({ displaycurrentQuality: true });

// var qualityLevels = videoPlayer.qualityLevels();

// for (var i = 0; i < videoPlayer.qualityLevels.length; i++) {
//   var quality = videoPlayer.qualityLevels[i];
//   if (quality.width >= 720) {
//     quality.enabled = true;
//   } else {
//     quality.enabled = false;
//   }
// }

Vue.use(VueVideoPlayer);
// Vue.use(hlsQualitySelector);
