const tdf = () => {
  if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    const args = [
      "\n %c 🏛️ TEMPIO DEL FUTURO *PERDUTO %c https://tempiodelfuturo.art",
      "font-weight: 900; letter-spacing: .2rem; font-size: 1.2em; border: 2px solid #fafafa;color: #fafafa; background: #1c1c1c; padding:5px 0; margin: 0 10px;",
      "color: #fafafa; background: #1c1c1c; padding:5px 0;",
    ];
    window.console.log.apply(console, args);
  } else if (window.console) {
    window.console.log(
      "🏛️ TEMPIO DEL FUTURO *PERDUTO - https://tempiodelfuturo.art"
    );
  }
};

export default tdf;
